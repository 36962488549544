<template>
  <b-modal
    :ref="modalRef"
    title="Cookie consent modal"
    size="lg"
    hide-header
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="container">
      <div class="row">
        <div class="col-sm-10">
          <h2>{{ $t('COOKIE_CONSENT.MODAL.HEADER') }}</h2>
        </div>
        <div v-if="multipleLanguages" class="col-sm-2 margin-bottom-4">
          <LanguageSwitcher />
        </div>
        <div class="col-sm-12 padding-top-10">
          <span class="modal-info">{{ $t('COOKIE_CONSENT.MODAL.INFO') }}</span>
        </div>
        <div class="checkboxes">
          <b-form-checkbox-group
            v-model="levelsSelected"
            name="consent-checkbox-group"
          >
            <div
              v-for="(option, index) in levelOptions"
              :key="index"
              class="col-sm-12 padding-top-16"
            >
              <b-form-checkbox
                :value="option.value"
                :disabled="option.disabled"
              >
                <span
                  class="cookie-title padding-left-8"
                  :class="{ 'color-disabled': option.disabled }"
                >
                  {{ $t(`COOKIE_CONSENT.MODAL.${option.value}.LABEL`) }}
                </span>
              </b-form-checkbox>
              <div
                class="padding-left-28 padding-top-8"
                :class="{ 'color-disabled': option.disabled }"
              >
                {{ $t(`COOKIE_CONSENT.MODAL.${option.value}.INFO`) }}
              </div>
            </div>
          </b-form-checkbox-group>
        </div>
        <div class="col-sm-12 padding-top-40">
          <Button variant="text" @click="handlePrivacyPolicyRedirect">
            {{ $t('COOKIE_CONSENT.MODAL.DETAILED_INFO') }}
          </Button>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <Button
          border="none"
          variant="inverse-primary"
          @click="setCookieConsent"
          size="large"
        >
          {{ $t('COOKIE_CONSENT.MODAL.SAVE_CHANGES') }}
        </Button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BFormCheckbox, BFormCheckboxGroup } from 'bootstrap-vue';
import { Button } from '@/components';
import { mapActions, mapState } from 'vuex';
import { HANDLE_COOKIE_CONSENT, SET_COOKIE_CONSENT } from '@/types';
import { constants } from '@/mixins';
import { COOKIE_CONSENT_LEVELS } from '@/constants';
import LanguageSwitcher from '@/containers/LanguageSwitcher';

export default {
  name: 'CookieModal',
  mixins: [constants],
  components: {
    LanguageSwitcher,
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    Button,
  },
  data() {
    return {
      levelsSelected: [],
    };
  },
  mounted() {
    this.levelsSelected = this.consentInfo?.levels || [
      COOKIE_CONSENT_LEVELS.NECESSARY,
    ];
  },
  watch: {
    consentInfo: {
      handler: function (val) {
        if (val && val.levels) {
          this.levelsSelected = val.levels;
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([SET_COOKIE_CONSENT, HANDLE_COOKIE_CONSENT]),
    setCookieConsent() {
      this.SET_COOKIE_CONSENT(this.levelsSelected);
      this.HANDLE_COOKIE_CONSENT(this.$gtm, this.$ga);
      this.hideModal();
    },
    showModal() {
      this.$emit('toggle-cookie-banner', false);
      this.$refs[this.modalRef].show();
    },
    hideModal() {
      this.$refs[this.modalRef].hide();
    },
    handlePrivacyPolicyRedirect() {
      window.location.href = `/documents/STIHLPrivacyPolicy.pdf`;
    },
  },
  computed: {
    ...mapState(['consentInfo']),

    modalRef() {
      return `cookie-consent-modal`;
    },
    levelOptions() {
      return Object.keys(COOKIE_CONSENT_LEVELS).map((option) => {
        return {
          value: option,
          disabled: option === COOKIE_CONSENT_LEVELS.NECESSARY,
        };
      });
    },
    multipleLanguages() {
      return this.STORE.availableLocales.length > 1;
    },
  },
};
</script>

<style lang="scss">
.modal-info {
  font-size: 22px;
}

.cookie-title {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

.save-setting-button {
  text-align: end;
}

.color-disabled {
  color: $color-grey-dark;
}

.checkboxes .active {
  background-color: $color-primary !important;
}
</style>
