import {
  API_BASE_URL,
  DELIVERY_TYPES,
  LOCALES,
  ORDER_STATUSES,
  REGEX,
  ROUTES,
  SERVICE_TYPES,
  STEPS,
  STORE,
  ICON_TYPES,
  IMAGE_BASE_URL,
  ENVIRONMENT,
} from '@/constants';

export const constants = {
  computed: {
    API_BASE_URL() {
      return API_BASE_URL;
    },
    IMAGE_BASE_URL() {
      return IMAGE_BASE_URL;
    },
    ROUTES() {
      return ROUTES;
    },
    SERVICE_TYPES() {
      return SERVICE_TYPES;
    },
    STEPS() {
      return STEPS;
    },
    REGEX() {
      return REGEX;
    },
    DELIVERY_TYPES() {
      return DELIVERY_TYPES;
    },
    ORDER_STATUSES() {
      return ORDER_STATUSES;
    },
    STORE() {
      return STORE;
    },
    LOCALES() {
      return LOCALES;
    },
    IS_MAINTENANCE() {
      return window.config.vue.vueAppMaintenance === 'true';
    },
    ICON_TYPES() {
      return ICON_TYPES;
    },
    ENVIRONMENT() {
      return ENVIRONMENT;
    },
  },
};

export const createOptions = {
  methods: {
    createOptions(options = {}, labelPrefix) {
      return Object.keys(options).map((optionValue) => ({
        value: optionValue,
        label: labelPrefix
          ? `${this.$t(`${labelPrefix}.${options[optionValue].toUpperCase()}`)}`
          : this.$t(options[optionValue].toUpperCase()),
      }));
    },
  },
};
