<template>
  <div
    :class="[
      'badge',
      { [`badge--variant-${variant}`]: !!variant },
      { [`badge--border-radius-${borderRadius}`]: !!borderRadius },
      { [`badge--clickable`]: clickable },
      { [`badge--active`]: active },
    ]"
    @click="clickable ? $emit('click') : null"
  >
    <CheckSVG v-if="clickable && active" class="icon badge__icon" />
    <span
      :class="[
        'badge__content',
        { 'badge__content--icon': clickable && active },
      ]"
    >
      <slot />
    </span>
    <button
      v-if="hasRemoveButton"
      type="button"
      class="badge__button"
      @click="$emit('click')"
    >
      <CloseSVG class="icon" />
    </button>
  </div>
</template>

<script>
import CheckSVG from '@/assets/images/check-solid.svg';
import CloseSVG from '@/assets/images/times-solid.svg';

export default {
  name: 'Badge',
  components: {
    CheckSVG,
    CloseSVG,
  },
  props: {
    variant: {
      validator: function (value) {
        return ['default', 'success', 'error', 'info', 'primary'].includes(
          value,
        );
      },
      default: 'default',
    },
    clickable: Boolean,
    active: Boolean,
    hasRemoveButton: Boolean,
    borderRadius: {
      validator: function (value) {
        return ['rounded', 'small'].includes(value);
      },
      default: 'rounded',
    },
  },
};
</script>

<style lang="scss">
.badge-group {
  > * {
    margin: 0.5rem;
  }
}

.badge {
  display: inline-flex;
  background: $color-grey-dark;
  color: $color-white;
  font-size: $font-size-extra-small;
  line-height: 1;
  text-transform: uppercase;
  align-items: center;
}

.badge--variant-success {
  background: $color-success;
}

.badge--variant-error {
  background: $color-error;
}

.badge--variant-info {
  background: $color-info;
}

.badge--variant-primary {
  background: $color-primary;
}

.badge--border-radius-rounded {
  border-radius: 99em;
}

.badge--border-radius-small {
  border-radius: rem(6px);
}

.badge--clickable {
  cursor: pointer;

  &:hover {
    box-shadow: $elevation;
  }
}

.badge--active {
  background: $color-black;
}

.badge__content {
  padding: rem(6px) 0.5rem;
}

.badge__icon {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.badge__button {
  display: flex;
  align-items: center;
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  -webkit-appearance: button;
  color: $color-white;
  padding: rem(4px) rem(4px) rem(4px) 0;
  opacity: 1;
  cursor: pointer;
}

.badge__button:hover {
  opacity: 0.7;
}

.badge__content--icon {
  padding-left: 0;
}
</style>
