// This file is quite pointless without custom VeeValidate.extend methods
// but it is here for the setup for future changes
import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesDK from 'vee-validate/dist/locale/da';

export const dictionary = {
  en: {
    ...validationMessagesEN,
    messages: {
      ...validationMessagesEN.messages,
    },
    custom: {
      renewalTerms: {
        required: 'All terms must be accepted.',
      },
      fairownRenewalTerms: {
        required: 'All terms must be accepted.',
      },
    },
  },
  da: {
    ...validationMessagesDK,
    messages: {
      ...validationMessagesDK.messages,
    },
    custom: {
      renewalTerms: {
        required: 'Alle vilkår skal accepteres.',
      },
      fairownRenewalTerms: {
        required: 'Alle vilkår skal accepteres.',
      },
    },
  },
};
