<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>LAD EN PROFESSIONEL KLARE ARBEJDET</h3>

        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <p>
                Tænk, hvis du havde din helt egen gartner, som altid sørgede
                for, at din græsplæne var perfekt klippet. Oplev fordelene ved
                STIHL All Inclusive - en finansieringsløsning, som gør det både
                nemt og bekvemt at eje en iMOW® robotplæneklipper.
              </p>
              <p>
                Alt er inkluderet i en fast månedlig pris - installation, årligt
                service og vinteropbevaring. Vælg mellem flere modeller, og find
                det perfekte match til dig og din græsplæne. Derefter beregnes
                den faste månedlige pris, og betalingen fordeles over 60
                måneder. Der er ingen renteomkostninger eller
                oprettelsesgebyrer. Den pris, du betaler i
                finansieringsperioden, er den pris, du også skulle have betalt
                for robotplæneklipper, installation, 4 x vinterservice og 4 x
                vinteropbevaring.
              </p>
              <p>Finansiering sker i samarbejde med Resurs Bank.</p>
            </Margins>
          </b-col>

          <b-col cols="6" sm="3" xl="3">
            <img
              :data-src="require('@/assets/images/sai_lp_s1_imows.png')"
              alt="Stihl All Inclusive price"
              class="lazyload margins__mobile-sm"
            />
          </b-col>
        </b-row>
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import { Container, Margins, Separator } from '@/components';
import SelectStore from '@/views/SelectStore/SelectStore';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS, ICON_TYPES } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Margins,
    SelectStore,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        'Kontakt din nærmeste <a href="https://stihlallinclusive.dk/ansoeg/vaelg-forhandler">STIHL forhandler</a>, og vælg den iMOW® model, der passer bedst til dit behov.',
        'Forhandleren installerer din nye iMOW® og gennemgår grundigt alle robottens funktioner med dig.*',
        'Du betaler en fast månedlig pris. Årligt service og vinteropbevaring er inkluderet i 4 år.',
        'Forny din aftale efter 4 år, og få en ny iMOW®. Eller behold den, du har, som din egen ejendom - efter samlet 5 år er den færdigbetalt.',
      ];
    },
    listWithIcons() {
      return [
        {
          icon: ICON_TYPES.INSTALLATION,
          label: 'INSTALLATION*',
        },
        {
          icon: ICON_TYPES.FOLLOW_UP,
          label: 'OPFØLGENDE BESØG AF DIN FORHANDLER ',
        },
        {
          icon: ICON_TYPES.WINTER_STORAGE,
          label: 'ÅRLIGT SERVICE OG VINTEROPBEVARING I 4 ÅR',
        },
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
