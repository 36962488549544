<template>
  <div class="select-store">
    <Card padding="large" variant="no-corner" class="select-store__content">
      <Margins>
        <CardHeader :header="view ? $t('DEALERS') : $t('PICK_CLOSEST_SHOP')" />

        <SelectStoreList
          class="margins__double"
          :stores="stores"
          :selectedStore="selectedStore"
          @zipSearch="handleZipSearch"
          @hoverStore="hoveredStore = $event"
          @chooseStore="saveOrderDeliveryInfo($event)"
        />
      </Margins>
    </Card>

    <SelectStoreMap
      :stores="stores"
      :hoveredStore="hoveredStore"
      :view="view"
      :zip="zip"
      @resetIndex="hoveredStore = $event"
      @selectStore="selectedStore = $event"
      @chooseStore="saveOrderDeliveryInfo($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { GET_STORES, SET_BUNDLE_SELECTED_STORE_CODE } from '@/types';
import { Card, CardHeader, Margins } from '@/components';
import { SelectStoreList, SelectStoreMap } from '@/containers/SelectStore';
import { constants } from '@/mixins';

export default {
  name: 'SelectStore',
  mixins: [constants],
  components: {
    Card,
    CardHeader,
    Margins,
    SelectStoreList,
    SelectStoreMap,
  },
  props: {
    view: Boolean,
  },
  data() {
    return {
      hoveredStore: null,
      selectedStore: null,
      zip: '',
    };
  },
  mounted() {
    this.GET_STORES();
  },
  methods: {
    ...mapActions([GET_STORES]),
    ...mapMutations([SET_BUNDLE_SELECTED_STORE_CODE]),
    saveOrderDeliveryInfo(store) {
      if (this.view) {
        return false;
      }

      this.SET_BUNDLE_SELECTED_STORE_CODE(store.code);

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION.name });
    },
    handleZipSearch(evt) {
      this.zip = evt;

      if (!this.zip) this.GET_STORES();
    },
  },
  computed: {
    ...mapState(['stores']),
  },
};
</script>

<style lang="scss">
.select-store {
  position: relative;
  min-height: calc(100vh - 93px);
  margin-bottom: -7rem;
}

.select-store__content {
  width: 100%;

  @include min-width(md) {
    width: 30vw;
    min-height: 100vh;
  }
}
</style>
