<template>
  <ul role="tablist" class="accordion">
    <li
      v-for="(item, index) in items"
      :key="index"
      role="tab"
      class="accordion__item"
    >
      <a
        v-if="hasAnchor"
        :id="getItemId(item)"
        :href="`#${getItemId(item)}`"
        @click="click($event, item)"
        v-b-toggle="`accordion-${getItemId(item)}`"
        class="accordion__title"
      >
        {{ item.title }}
      </a>

      <button
        v-else
        type="button"
        v-b-toggle="`accordion-${getItemId(item)}`"
        class="accordion__title"
      >
        {{ item.title }}
      </button>

      <b-collapse
        :id="`accordion-${getItemId(item)}`"
        :accordion="name"
        role="tabpanel"
      >
        <div class="accordion__content">
          <Margins>
            <template v-for="(desc, index) in getParagraphs(item)">
              <p v-html="desc" :key="index" />
            </template>
          </Margins>
        </div>
      </b-collapse>
    </li>
  </ul>
</template>

<script>
import { Margins } from '@/components';
import kebabCase from 'lodash/kebabCase';

export default {
  name: 'Accordion',
  components: {
    Margins,
  },
  props: {
    name: {
      type: String,
      default: 'default-accordion',
    },
    items: [Array, Object],
    hasAnchor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getParagraphs(item) {
      return Object.keys(item)
        .filter((i) => i.startsWith('description'))
        .map((d) => item[d]);
    },
    getItemId(item) {
      return `${this.name}-${kebabCase(item.title)}`;
    },
    click(event, item) {
      this.$emit('itemClicked', event, this.getItemId(item));
    },
  },
};
</script>

<style lang="scss">
.accordion {
  list-style-type: none;
  padding-bottom: 1rem;
}

.accordion__item {
  + .accordion__item {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: rem(1px) solid $color-grey-border;
  }
}

.accordion__title {
  position: relative;
  display: block;
  background: transparent;
  border: 0;
  font-family: $font-family-header;
  font-weight: $font-weight-bold;
  font-size: 1rem;
  padding: 0 0 0 rem(48px);
  margin: 0;
  text-align: left;
  width: 100%;
  cursor: pointer;

  &::before {
    content: '\200B'; /* Add zero-width space */
    position: absolute;
    left: 0;
    top: rem(-4px);
    background-repeat: no-repeat;
    background-position: center center;
    background-image: $accordion-icon-closed;
    height: rem(26px);
    width: rem(26px);
    transition: transform 0.25s ease-in;
  }

  &:not(.collapsed) {
    &::before {
      background-image: $accordion-icon-opened;
    }
  }
}

.accordion__content {
  padding: 1rem 0 0 rem(48px);
}
</style>
