export const API_BASE_URL = process.env.VUE_APP_API_URL;
export const IMAGE_BASE_URL = process.env.VUE_APP_IMAGE_BASE_URL;
export const SELLER_CODE = process.env.VUE_APP_SELLER_CODE;
export const SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL;
export const MAIN_LOCALE = window.config.vue.vueAppI18nLocale;
export const AVAILABLE_LOCALES = (
  window.config.vue.vueAppI18nAvailableLocales || MAIN_LOCALE
).split(',');
export const ENVIRONMENT = window.config.vue.vueAppEnvironment;

export const ROUTES = {
  HOME: {
    path: '/',
    name: 'home',
  },
  MAINTENANCE: {
    path: '/maintenance',
    name: 'maintenance',
  },
  SELECT_BUNDLE: {
    path: '/vaelg-model',
    name: 'select-bundle',
  },
  APPLY: {
    path: '/ansoeg',
    name: 'apply',
  },
  SELECT_STORE: {
    path: '/ansoeg/vaelg-forhandler',
    name: 'select-store',
  },
  FAQ: {
    path: '/spoergsmaal',
    name: 'faq',
  },
  LOAN_APPLICATION: {
    path: '/ansoeg/laaneansoegning',
    name: 'loan-application',
  },
  LOAN_APPLICATION_LOADING: {
    path: '/ansoeg/laaneansoegning/indsender',
    name: 'loan-application-loading',
  },
  LOAN_APPLICATION_SUCCESS: {
    path: '/ansoeg/laaneansoegning/gennemfoert',
    name: 'loan-application-success',
  },
  LOAN_APPLICATION_FAIL: {
    path: '/ansoeg/laaneansoegning/fejl',
    name: 'loan-application-fail',
  },
  LOAN_APPLICATION_REJECT: {
    path: '/ansoeg/laaneansoegning/afvist',
    name: 'loan-application-reject',
  },
  LOAN_APPLICATION_RENEW: {
    path: '/ansoeg/laaneansoegning/forny',
    name: 'loan-application-renew',
  },
  LOAN_PAYMENT_LOADING: {
    path: '/ansoeg/betaling/indsender',
    name: 'loan-payment-loading',
  },
  LOAN_APPLICATION_INSPECTION: {
    path: '/ansoeg/laaneansoegning/inspektion',
    name: 'loan-application-inspection',
  },
  LOGIN_SUCCESS: {
    path: '/login-gennemfoert',
    name: 'login-success',
  },
  LOGIN_FAIL: {
    path: '/login-fejl',
    name: 'login-fail',
  },
  CREDIT_CARD_PAYMENT: {
    path: '/kreditkort/betaling',
    name: 'credit-card-payment',
  },
  CREDIT_CARD_PAYMENT_FAIL: {
    path: '/kreditkort/betaling-mislykkes',
    name: 'credit-card-payment-fail',
  },
};

export const SERVICE_TYPES = {
  FOLLOW_UP: 'FOLLOW_UP',
  WINTER_STORAGE: 'WINTER_STORAGE',
  INSTALLATION: 'INSTALLATION',
  PERFECT_GRASS: 'PERFECT_GRASS',
};

export const PERSISTED_STORE_KEY = 'fairown-persisted-store';

export const STEPS = {
  select: 0,
  apply: 1,
  paymentSubmitted: 2,
};

export const REGEX = {
  DK: {
    identificationNumber:
      '^((3[0-1])|([1-2][0-9])|(0[1-9]))((1[0-2])|(0[1-9]))(\\d{2})([\\d]{4})$',
    mobile: '^(\\+45|0045|)?[ |-]?[2-9]([ |-]?[0-9]){7}$',
    postalCode: '^\\d{4}$',
    serialNumber: '^\\d{9}$',
  },
};

export const DELIVERY_TYPES = {
  STORE: 'STORE',
};

export const ORDER_STATUSES = {
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  HANDED_OVER: 'HANDED_OVER',
};

export const LOCALES = {
  EN: 'en',
  DK: 'da',
};

export const STORE = {
  name: 'Stihl',
  lang: 'da',
  availableLocales: AVAILABLE_LOCALES,
  mapConfig: {
    centerPosition: { lat: 56.2265414, lng: 10.4217051 },
    zoom: 7,
  },
};

export const ICON_TYPES = {
  ROBOTIC_LAWN_MOWER: 'ROBOTIC_LAWN_MOWER',
  BATTERY_TRIMMER: 'BATTERY_TRIMMER',
  BATTERY_HEDGE_TRIMMER: 'BATTERY_HEDGE_TRIMMER',
  BATTERY_LEAF_BLOWER: 'BATTERY_LEAF_BLOWER',
  BATTERY: 'BATTERY',
  CHARGER: 'CHARGER',
  INSTALLATION: 'INSTALLATION',
  FOLLOW_UP: 'FOLLOW_UP',
  WINTER_STORAGE: 'WINTER_STORAGE',
  PERFECT_GRASS: 'PERFECT_GRASS',
};

export const RESURS_DECISION_STATUSES = {
  STARTED: 'STARTED',
  FROZEN: 'FROZEN',
  RESERVED: 'RESERVED',
  DECLINED: 'DECLINED',
};

export const COOKIE_CONSENT_LEVELS = {
  NECESSARY: 'NECESSARY',
  ANALYTIC: 'ANALYTIC',
  TARGETING: 'TARGETING',
};

const socialMediaItems = [
  {
    icon: 'BIconFacebook',
    url: 'https://www.facebook.com/StihlDanmark?brand_redir=DISABLE',
    label: 'FACEBOOK',
  },
  {
    icon: 'BIconInstagram',
    url: 'https://www.instagram.com/stihl_danmark/',
    label: 'INSTAGRAM',
  },
  {
    icon: 'BIconYoutube',
    url: 'https://www.youtube.com/channel/UC7MLBDxXM4yHHZubOZnV9lg',
    label: 'YOUTUBE',
  },
  {
    icon: 'BIconLinkedin',
    url: 'https://uk.linkedin.com/company/stihl-danmark',
    label: 'LINKEDIN',
  },
];

const contactItems = [
  { label: 'country', value: 'STIHL Danmark' },
  { label: 'street', value: 'Park Allé 295' },
  { label: 'postal code', value: '2605 Brøndby' },
  { label: 'email', value: 'info@stihl.dk' },
];

const companyItems = [
  { label: 'ABOUT_US', url: 'https://www.stihl.dk/vi-om-os.aspx' },
];

export const footerDataMap = new Map([
  ['SocialMediaItems', socialMediaItems],
  ['ContactItems', contactItems],
  ['CompanyItem', companyItems],
]);

export const akBatterySkuCodes = ['45220115730', '45204006515', '45210113500'];

export const apBatterySkuCodes = [
  'FA050115700',
  '48510113529',
  '48504006560',
  'FA012000001',
  'BA020115900',
];

export const bundleHeroImageMap = {
  IMOW_RMI: 'sai_rmi_hero.jpg',
  IMOW: 'sai_imow_hero.jpg',
  IMOW_EVO: 'sai_imow-evo_hero.jpg',
  BASE: 'hero.jpg',
};
