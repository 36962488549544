<template>
  <form @submit="handleSubmit" class="loan-application-form" autocomplete="off">
    <Input
      name="bot-field"
      label="Don’t fill this out if you're human"
      v-model="formData.honeypot"
      autocomplete="random"
      isHoneyPot
    />

    <Margins>
      <b-row>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.FIRST_NAME')} *`"
            name="firstName"
            autocomplete="first-name"
            v-model.trim="formData.firstName"
            :error="errors.first('firstName')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.LAST_NAME')} *`"
            name="lastName"
            autocomplete="family-name"
            v-model.trim="formData.lastName"
            :error="errors.first('lastName')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
            class="margins__mobile-md-double"
          />
        </b-col>
      </b-row>

      <Input
        :label="`${$t('FORM.ID_NUMBER')} *`"
        name="identificationNumber"
        placeholder="0000000000"
        v-model.trim="formData.personalId"
        :error="errors.first('identificationNumber')"
        v-validate="{
          required: true,
          regex: REGEX.DK.identificationNumber,
        }"
        :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
        class="margins__double"
      />

      <b-row class="margins__double">
        <b-col md="6">
          <Input
            type="email"
            :label="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.MOBILE')} *`"
            name="mobile"
            placeholder="+45-000000000"
            autocomplete="tel"
            v-model.trim="formData.phoneNumber"
            :error="errors.first('mobile')"
            v-validate="{
              required: true,
              regex: REGEX.DK.mobile,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.MOBILE')"
            prefix="🇩🇰"
            class="margins__mobile-md-double"
            @change="removeWhitespace"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col>
          <Input
            :label="`${$t('FORM.ADDRESS')} *`"
            name="street"
            autocomplete="street-address"
            v-model.trim="formData.deliveryAddress.street"
            :error="errors.first('street')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.ADDRESS')"
          />
        </b-col>
        <b-col md="2">
          <Input
            :label="`${$t('FORM.ZIP_CODE')} *`"
            placeholder="0000"
            name="postalCode"
            autocomplete="postal-code"
            v-model.trim="formData.deliveryAddress.postalCode"
            :error="errors.first('postalCode')"
            v-validate="{
              required: true,
              regex: REGEX.DK.postalCode,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.ZIP_CODE')"
            class="margins__mobile-md-double"
          />
        </b-col>
        <b-col>
          <Input
            :label="`${$t('FORM.CITY')} *`"
            name="city"
            autocomplete="address-level2"
            v-model.trim="formData.deliveryAddress.city"
            :error="errors.first('city')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.CITY')"
            class="margins__mobile-md-double"
          />
        </b-col>
      </b-row>

      <Separator hasLine size="large" />

      <Check
        :label="privacyPolicyConsent"
        name="privacyConsent"
        v-model="formData.privacyConsent"
        v-validate="{ required: true }"
      />

      <div v-if="errors.first('privacyConsent')" class="check__error">
        {{ $t('FORM.VALIDATOR.TERMS') }}
      </div>

      <div class="text-center margins__triple">
        <p>{{ $t('FORM.PERSONAL_INFO_CONSENT') }}</p>
      </div>

      <div class="text-center">
        <p>{{ $t('FORM.CREDIT_CHECK_INFO') }}</p>
      </div>

      <div class="text-center">
        <Button type="submit" variant="inverse-primary">
          {{ $t('CONTINUE') }}
        </Button>
      </div>
    </Margins>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SET_LOAN_APPLICATION_DATA } from '@/types';
import { Button, Check, Input, Margins, Separator } from '@/components';
import { constants, createOptions } from '@/mixins';
import { getPersistedStore } from '@/utils';
import { buildLoanApplicationData } from '@/utils/BundleUtil';

export default {
  name: 'LoanApplicationForm',
  mixins: [constants, createOptions],
  components: {
    Input,
    Margins,
    Button,
    Check,
    Separator,
  },
  data() {
    return {
      formData: {
        deliveryAddress: {},
        phoneNumber: '',
      },
    };
  },
  created() {
    const localLoanApplicationData = getPersistedStore().loanApplicationData;

    if (!localLoanApplicationData) {
      return false;
    }

    this.formData = this.removeConsents(localLoanApplicationData);
  },
  methods: {
    ...mapActions([SET_LOAN_APPLICATION_DATA]),
    removeConsents(object) {
      const { privacyConsent, ...formData } = object; // eslint-disable-line no-unused-vars
      return formData;
    },
    removeWhitespace() {
      this.formData.phoneNumber = this.formData.phoneNumber.replace(/\s+/g, '');
      return !!this.$validator.validate(
        'phoneNumber',
        this.formData.phoneNumber,
      );
    },
    async handleSubmit(e) {
      e.preventDefault();

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      const clonedFormData = JSON.parse(JSON.stringify(this.formData));
      clonedFormData.deliveryAddress.postalCode =
        clonedFormData.deliveryAddress.postalCode.replace(/\s+/g, '');
      clonedFormData.privacyConsent = !!clonedFormData.privacyConsent;

      this.SET_LOAN_APPLICATION_DATA(
        buildLoanApplicationData(
          clonedFormData,
          this.selectedBundle.id,
          this.storeCode,
        ),
      );

      this.$gtag?.event('application_submit');

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION_LOADING.name });

      this.formData = {};
      this.$validator.reset();
    },
  },
  computed: {
    ...mapState(['selectedBundle', 'loanApplicationData', 'storeCode']),
    privacyPolicyConsent() {
      return `<p>${this.$t('FORM.PRIVACY_CONSENT', [
        `<a href="${window.location.origin}/documents/FairownPrivacyPolicyDK.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
  },
};
</script>
