<template>
  <aside class="toast" v-if="isShown">
    <button
      type="button"
      class="toast__close"
      aria-label="Close"
      @click="closeToast"
    >
      <CloseSVG class="icon" />
    </button>
    <p v-html="message" />
  </aside>
</template>

<script>
import CloseSVG from '@/assets/images/times-circle-regular.svg';

export default {
  name: 'Toast',
  components: {
    CloseSVG,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShown: true,
    };
  },
  methods: {
    closeToast() {
      this.isShown = false;
    },
  },
};
</script>

<style lang="scss">
.toast {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  background: $color-white;
  border-radius: $border-radius;
  box-shadow: $elevation;
  border: 1px solid $color-grey-border;
  padding: 1rem 2rem 1rem 1rem;
  max-width: rem(400px);
  z-index: 1;
}

.toast__close {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: $color-black;
  font-size: 1.25rem;
  padding: rem(10px);
  z-index: 1;

  &:hover {
    color: $color-grey-dark;
  }
}
</style>
