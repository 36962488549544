var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'card',
    {
      [`card--padding-${_vm.padding}`]: !!_vm.padding,
    },
    {
      [`card--color-${_vm.color}`]: !!_vm.color,
    },
    {
      [`card--variant-${_vm.variant}`]: !!_vm.variant,
    },
    {
      [`card--overflow-${_vm.overflow}`]: !!_vm.overflow,
    },
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }