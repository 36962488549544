<template>
  <div class="maintenance">
    <Container>
      <Card padding="large">
        <Margins>
          <img src="@/assets/images/stihl-logo.png" alt="Stihl ALL Inclusive" />
          <Separator size="large" />
          <h2 class="text-center">{{ $t('MAINTENANCE.COMING_SOON') }}</h2>
          <Separator size="large" />
        </Margins>
      </Card>
    </Container>
  </div>
</template>
‌
<script>
import { Card, Container, Margins, Separator } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'Maintenance',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Separator,
  },
};
</script>

<style lang="scss">
.maintenance {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 9rem);
}
</style>
