<template>
  <div id="app">
    <Header v-if="!IS_MAINTENANCE" />
    <main>
      <transition name="fade-router" mode="out-in">
        <router-view />
      </transition>

      <Alert v-if="hasError" type="error" position="fixed" isDismissable>
        <Margins>
          <p v-html="errorMessage" />
          <p class="text-extra-small" v-html="errorCode"></p>
        </Margins>
      </Alert>

      <Alert v-if="notification" type="success" position="fixed" isDismissable>
        <Margins>
          <p v-html="notification" />
        </Margins>
      </Alert>
    </main>
    <CookieBanner
      v-if="isCookieBannerOpen"
      @open-cookie-consent-modal="showCookieConsentModal"
    />
    <CookieModal
      :ref="cookieConsentModalRef"
      @toggle-cookie-banner="toggleCookieBanner"
    />
    <Footer @open-cookie-consent-modal="showCookieConsentModal" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Footer, Header } from '@/containers';
import { Alert, Margins, CookieModal, CookieBanner } from '@/components';
import { constants } from '@/mixins';
import { SUPPORT_EMAIL } from '@/constants';
import { HANDLE_COOKIE_CONSENT, SET_COOKIE_CONSENT } from '@/types';

export default {
  name: 'App',
  mixins: [constants],
  data() {
    return {
      cookieBannerOpen: true,
    };
  },
  components: {
    CookieModal,
    CookieBanner,
    Header,
    Alert,
    Margins,
    Footer,
  },
  async mounted() {
    this.handleConsent();
  },
  methods: {
    ...mapActions([SET_COOKIE_CONSENT, HANDLE_COOKIE_CONSENT]),
    showCookieConsentModal() {
      this.$refs[this.cookieConsentModalRef].showModal();
    },
    toggleCookieBanner(open) {
      this.cookieBannerOpen = open;
    },
    handleConsent() {
      this.HANDLE_COOKIE_CONSENT();
    },
  },
  computed: {
    ...mapState(['hasError', 'notification', 'consentInfo']),
    errorMessage() {
      return this.$t('DEFAULT_ERROR_MESSAGE', [
        '<br />',
        `<a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`,
      ]);
    },
    errorCode() {
      return this.$t('DEFAULT_ERROR_CODE', [
        this.hasError.message || this.hasError.status,
      ]);
    },
    cookieConsentModalRef() {
      return `cookie-consent-modal`;
    },
    isCookieBannerOpen() {
      return this.cookieBannerOpen && !this.consentInfo?.levels?.length;
    },
  },
};
</script>

<style lang="scss">
.fade-router-enter-active,
.fade-router-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-router-enter,
.fade-router-leave-active {
  opacity: 0;
}
</style>
